
 import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
 import clientManager from '@/util/client';
 import ManagementMixin from '@/mixins/ManagementMixin';
import Config from '@/config';
import store from '@/store';
import { checkPermission } from '@/api/utils';
export default {
  mixins: [ManagementMixin],
  props:{
    title: { type: String, required: true },
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated', 'getLocale', 'getToken'])
  },
created() {
   // this.baseUrl = Config.sso;
  },
  mounted() {
    const locale = store.getters['user/getLocale'];
    const Token = this.getToken;
    const reportingUrl = Config.reportingURL;
    const flowgoUrl = Config.ssoUrl;
    const flowgoUrl2 = Config.flowgo2Url;
    const baseurl = Config.sso;
    const getRoute = this.$router;
    const endURL = getRoute.history.current.name;
    const createPermission =  checkPermission('FlowAssistantExplorerCreate');
    const EditPermission = checkPermission('FlowAssistantExplorerEdit');
    const deletePermission = checkPermission('FlowAssistantExplorerDelete');
// debugger;
//  setTimeout(function () {
  renderWolfiSettings2('flow-wolfi',{
          baseUrl: baseurl,
          token: "Bearer"+Token,
          lang: locale,
          reporting: reportingUrl,
          flowgo: flowgoUrl,
          flowgo2: flowgoUrl2,
          clickedPage: endURL,
          createPermission: createPermission,
          EditPermission: EditPermission,
          deletePermission: deletePermission
        });

      // }, 150);
},
  methods:{

  },

};
